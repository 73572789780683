/*
For API Handling ( Login, GET, POST, PUT, PATCH, DELETE )
--  For API request preparation
--  For API response handling
*/
import axios from "axios";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { findDefaultBu } from "../helper/commonHelper";

export const apiIpPort = process.env.REACT_APP_IAM_API_ENDPOINT;
export const apiBaseUrl = process.env.REACT_APP_IAM_API_BASEURL;
export const configurationApiEndpoint =
  process.env.REACT_APP_CONFIGURATION_API_ENDPOINT;
export const alertApiEndpoint = process.env.REACT_APP_ALERT_API_ENDPOINT;
const snopEndpoint = process.env.REACT_APP_SNOP_API_ENDPOINT;
const configurationApiBaseUrl = process.env.REACT_APP_CONFIGURATION_API_BASEURL;
const snopApiBaseUrl = process.env.REACT_APP_SNOP_API_BASEURL;
const OKTA_HOST = process.env.REACT_APP_OKTA_HOST;
const HOST = process.env.REACT_APP_CLIENT_HOST;
const CLIENT_CODE = process.env.REACT_APP_CLIENT_CODE;
const OKTA_CALLBACK = process.env.REACT_OKTA_CALLBACK ?? "";
const REDIRECT_URI = `${HOST}${OKTA_CALLBACK}`;
const DEFAULT_KEY_CODE = process.env.REACT_APP_DEFAULT_CODE;

//Login
export const loginUser = async (email, password) => {
  const body = {
    email,
    password,
  };

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.post(
      `${apiIpPort}${apiBaseUrl}/users/login`,
      body,
      config
    );
    const user = res.data.data;
    const bu = findDefaultBu(user?.business_units || []);
    const selected_business_unit = {
      business_unit_id: bu.business_unit_id,
      business_unit_name: bu.business_unit_name,
    };
    // const newUser = userObj(user);
    // localStorage.setItem("user", JSON.stringify(newUser));
    Cookies.set("token", JSON.stringify(user.access), { expires: 1 });
    localStorage.setItem("refresh", JSON.stringify(user.refresh));
    localStorage.setItem(
      "selected_business_unit",
      JSON.stringify(selected_business_unit)
    );
    return res;
  } catch (error) {
    console.error(error);
    if (error?.response?.status == 401) {
      toast.error(error?.response?.data?.message);
      throw new Error(JSON.stringify(error?.response?.data?.message));
    }
    if (
      typeof error?.response?.data === "undefined" ||
      typeof error?.response?.data?.message === "undefined"
    ) {
      toast.error(error?.message);
    }
    toast.error(error?.response?.data?.message);
  }
};

export const loginMFA = async (binding_code, oob_code, mfa_token, email) => {
  const body = {
    binding_code,
    oob_code,
    mfa_token,
    email,
  };

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.post(
      `${apiIpPort}${apiBaseUrl}/users/login_mfa`,
      body,
      config
    );
    const user = res.data.data;
    const bu = findDefaultBu(user?.business_units || []);
    const selected_business_unit = {
      business_unit_id: bu.business_unit_id,
      business_unit_name: bu.business_unit_name,
    };
    // const newUser = userObj(user);
    // localStorage.setItem("user", JSON.stringify(newUser));
    Cookies.set("token", JSON.stringify(user.access), { expires: 1 });
    localStorage.setItem("refresh", JSON.stringify(user.refresh));
    localStorage.setItem(
      "selected_business_unit",
      JSON.stringify(selected_business_unit)
    );
    // delete user?.access;
    // delete user?.refresh;
    // setUserLocalStorage(user)
    return res;
  } catch (error) {
    console.error(error);
    if (
      typeof error?.response?.data === "undefined" ||
      typeof error?.response?.data?.message === "undefined"
    ) {
      toast.error(error?.message);
    }
    toast.error(error?.response?.data?.message);
  }
};

export const getConfiguration = async (token, tenant_id, bu_id) => {
  try {
    const tokenConfig = {
      headers: { Authorization: `Bearer ${token}` },
      params: {},
    };
    const configurationResult = await axios.get(
      `${configurationApiEndpoint}${configurationApiBaseUrl}?tenant_id=${tenant_id}&bu_id=${bu_id}`,
      tokenConfig
    );
    localStorage.setItem(
      "configuration",
      JSON.stringify(configurationResult.data.responseData.response)
    );
    return configurationResult;
  } catch (error) {
    if (
      typeof error?.response?.data === "undefined" ||
      typeof error?.response?.data?.message === "undefined"
    ) {
      toast.error(error?.message);
    }
    toast.error(error?.response?.data?.message);
  }
};

export const getActiveSnop = async (token, tenant_id, bu_id) => {
  try {
    const tokenConfig = {
      headers: { Authorization: `Bearer ${token}` },
      params: {},
    };
    const snopResult = await axios.get(
      `${snopEndpoint}${snopApiBaseUrl}?tenant_id=${tenant_id}&bu_id=${bu_id}`,
      tokenConfig
    );
    if (snopResult?.data?.responseData?.data) {
      localStorage.setItem(
        "snop",
        JSON.stringify(snopResult?.data?.responseData?.data)
      );
    }
    return snopResult;
  } catch (error) {}
};

export const setPassword = async (password, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const body = {
    password: password,
  };

  try {
    const res = await axios.patch(
      `${apiIpPort}${apiBaseUrl}/users/set-password`,
      body,
      config
    );
    return res;
  } catch (error) {
    console.error(error);
    if (
      typeof error?.response?.data === "undefined" ||
      typeof error?.response?.data?.message === "undefined"
    ) {
      toast.error(error?.message);
    }
    toast.error(error?.response?.data?.message);
  }
};

export const forgotPassword = async (email) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = {
    email: email,
  };

  try {
    const res = await axios.post(
      `${apiIpPort}${apiBaseUrl}/users/forgot-password`,
      body,
      config
    );
    return res;
  } catch (error) {
    console.error(error);
    if (
      typeof error?.response?.data === "undefined" ||
      typeof error?.response?.data?.message === "undefined"
    ) {
      toast.error(error?.message);
    }
    toast.error(error?.response?.data?.message);
  }
};

export const tokenVerify = async (token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = {
    token: token,
  };

  return await axios.post(
    `${apiIpPort}${apiBaseUrl}/users/token/verify`,
    body,
    config
  );
};

export const tokenRefresh = async (token, authenticator) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = {
    refresh: token,
    authenticator: authenticator ?? "",
  };

  return await axios.post(
    `${apiIpPort}${apiBaseUrl}/users/token/refresh`,
    body,
    config
  );
};

export const authorizeOkta = async (codeChallenge) => {
  if (codeChallenge.length > 0) {
    const URL = `${OKTA_HOST}/oauth2/${DEFAULT_KEY_CODE}/v1/authorize?client_id=${CLIENT_CODE}&response_type=code&scope=openid offline_access profile email&redirect_uri=${REDIRECT_URI}&state=state-8600b31f-52d1-4dca-987c-386e3d8967e9&code_challenge_method=S256&code_challenge=${codeChallenge}`;
    window.location.replace(URL);
  }
};

export const validateOKTALogin = async (auth, verifier) => {
  const body = {
    auth_code: auth,
    code_verifier: verifier,
  };
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.post(
      `${apiIpPort}${apiBaseUrl}/users/login/okta`,
      body,
      config
    );
    const user = res.data.data;
    const bu = findDefaultBu(user?.business_units || []);
    const selected_business_unit = {
      business_unit_id: bu.business_unit_id,
      business_unit_name: bu.business_unit_name,
    };
    // const newUser = userObj(user);
    // localStorage.setItem("user", JSON.stringify(newUser));
    Cookies.set("token", JSON.stringify(user.access), { expires: 1 });
    localStorage.setItem("refresh", JSON.stringify(user.refresh));
    localStorage.setItem(
      "selected_business_unit",
      JSON.stringify(selected_business_unit)
    );
    // delete user?.access;
    // delete user?.refresh;
    // setUserLocalStorage(user)
    return res;
  } catch (error) {
    console.error(error);
    if (error?.response?.status == 401) {
      throw new Error(error?.response?.data?.message);
    }
    if (
      typeof error?.response?.data === "undefined" ||
      typeof error?.response?.data?.message === "undefined"
    ) {
      toast.error(error?.message);
    }
    toast.error(error?.response?.data?.message);
  }
};

export function logout() {
  localStorage.removeItem("user");
  Cookies.remove("user");
  localStorage.removeItem("refresh");
  localStorage.removeItem("persist:root");
  localStorage.removeItem("snop");
  localStorage.removeItem("configuration");
  localStorage.removeItem("mainMenu");
  Cookies.remove("token");
  sessionStorage.clear();
}
